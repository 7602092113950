<!-- Controls the table and home screen for billing. -->
<template>
  <v-container class="pa-0 ma-0" fluid v-resize="onResize">
    <v-toolbar flat color="toolbar">
      <v-toolbar-title class="ml-2 title">
        Batch Billing
      </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-row align="center" class="mt-4">
        <v-col md="4" lg="5" xl="5" class="pr-0">
          <!-- Text search for billing home screen-- this is an API search that returns batches based on key/value -->
          <v-text-field
            @keydown.enter="searchBatches()"
            class="ma-0 pa-2 pt-3 pb-4 pr-0"
            flat
            solo
            style="border-bottom-right-radius:0px; border-top-right-radius:0px;"
            :placeholder="search.key ? `Search By ${search.key.description}` : 'Select Search Field'"
            outlined
            dense
            height="10px"
            background-color="input"
            v-model="search.value">
            <template #prepend-inner>
              <v-autocomplete
                class="mx-1 pb-2 mt-0 pt-8"
                placeholder="Search By"
                append-icon=":"
                :items="searchParams"
                flat
                solo
                item-text="description"
                return-object
                dense
                v-model="search.key">
              </v-autocomplete>
            </template>
            <template #append>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    color="primary"
                    v-on="on"
                    @click="searched === false ? searchBatches() : clearSearch()"
                    :loading="searching"
                    :disabled="buttonDisabled"
                    small>
                    <v-icon class="white--text">
                      {{ searched ? 'mdi-close' : 'mdi-magnify' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ searched ? 'Clear Search' : 'Search Batches' }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <!-- Menu to filter batches based on status. Served by 2 lists in data, one which holds all statuses and the other holds the selected statuses -->
        <v-menu
          open-on-hover
          transition="slide-y-transition"
          bottom offset-y
          :nudge-left="75"
          :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              @click.prevent height="40"
              v-bind="attrs"
              v-on="on"
              style="border-bottom-left-radius:0px;border-top-left-radius:0px; border:1px solid #9e9e9e; border-left-style:hidden; margin-bottom:30px;">
              <v-icon color="main">mdi-filter-outline</v-icon>
            </v-btn>
          </template>
          <v-card class="mx-auto" max-width="300" tile>
            <v-list dense>
              <v-card-title class="text-subtitle-2">
                Filter by Status
              </v-card-title>
              <v-list-item-group color="primary">
                <v-list-item v-for="state, i in statusList" :key="i">
                  <v-list-item-action>
                    <v-checkbox dense :key="i" :value="state" v-model="states">
                      <template #label>
                        <span class="ml-2">{{ state }}</span>
                      </template>
                    </v-checkbox>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
        <v-spacer/>
        <!-- Post batches button, disabled unless batches are selected from the home screen. Opens a warning modal. -->
        <v-btn
          height="40"
          color="#0c67a5"
          class="white--text mb-7"
          :disabled="selected.length === 0"
          @click="component='PostBatch', selectedBatches=selected, modal=true">
          Post Selected Batch
          <v-icon color="#fff" right>mdi-arrow-up-bold-box</v-icon>
        </v-btn>
        <v-btn
          class="mx-2 mb-7"
          height="40"
          color="success"
          @click="component='BatchModal', modal=true">
          New Batch
        </v-btn>
      </v-row>
    </v-toolbar>
    <v-divider></v-divider>
    <!-- Basic batch table. Has pagination on pageSize and headers from computed properties. Batches are served from an API call. -->
    <v-data-table
      dense
      :items-per-page="pageSize"
      :headers="headers"
      :height="tableSize"
      :items.sync="selectedItems"
      :loading="loading"
      v-ripple="false"
      v-model="selected"
      item-key="id"
      :search="filter"
      class="table"
      show-select
      single-select
      fixed-header
      hide-default-footer>
      <!-- Batch table slot overrides -->
      <template #[`header.info`]="{ header }">
        <span class="noWrap">{{ header.text }}</span>
      </template>
      <template #[`header.general_col_divider`]="{}">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn v-on="on" class="white--text"
                  icon small @click="column1 = !column1">
                <v-icon v-if="!column1">
                  mdi-chevron-left
                </v-icon>
                <v-icon v-if="column1">
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </template>
            <span>{{ column1 ? 'Collapse' : 'Expand' }}</span>
          </v-tooltip>
        </template>
      <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
        <v-simple-checkbox
          v-if="item.status === 'COMPLETE'"
          :value="isSelected"
          @input="select">
        </v-simple-checkbox>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          small
          dark
          :color="getColor(item.status)">
          <span style="display: inline-block; margin: 0 auto; width: 95px; text-align: center; font-size:11px">
            {{ item.status.replace(/_/g," ") }}
          </span>
        </v-chip>
      </template>
      <template v-slot:[`item.start_date`]="{ item }">
        <span class="noWrap" v-if="item && item.start_date">
          {{ formatDate(item.start_date) }}
        </span>
      </template>
      <template v-slot:[`item.end_date`]="{ item }">
        <span class="noWrap" v-if="item && item.end_date">
          {{ formatDate(item.end_date) }}
        </span>
      </template>
      <template v-slot:[`item.post_date`]="{ item }">
        <span class="noWrap" v-if="item && item.post_date">
          {{ formatDate(item.post_date) }}
        </span>
        <span v-else>---</span>
      </template>
      <template #[`item.post_id`]="{item}">
          <span
            class="noWrap"
            v-if="item.post_id && column1"
            style='cursor:pointer'
            @click.stop="copyValue(item.post_id)">
            {{ item.post_id.substring(0,8).toUpperCase() }}
          </span>
          <span v-else>---</span>
      </template>
      <template #[`item.gen_col_divider`]="{}">
          <v-divider vertical/>
        </template>
      <template v-slot:[`item.last_update_date`]="{ item }">
        <span class="noWrap" v-if="item && item.last_update_date && column1">
          {{ formatDateTime(item.last_update_date) }}
        </span>
      </template>
      <template #[`item.info`]="{item}">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn v-on="on" icon @click.stop="copyValue(item.id)">
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </template>
          <span>{{ item.id }}</span>
        </v-tooltip>
      </template>
      <template #[`item.user_id`]="{ item }">
        <div v-if="column1" class="truncate">{{ item.user_id }}</div>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-menu open-on-click transition="slide-x-transition" bottom right offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card class="mx-auto" max-width="300" tile>
            <v-list dense>
              <v-subheader>Actions</v-subheader>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(action, i) in actions"
                  :key='i'
                  :disabled="isDetails(action.description, item.status)"
                  @click="selectedBatch=item, component=action.component, modal=true">
                  <v-list-item-action>
                    <v-icon
                      :disabled="isDetails(action.description, item.status)">
                      {{ action.icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-list-item-title>
                      {{ action.description }}
                    </v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item 
                  v-if="isCancellable(item)"
                  @click="selectedBatch=item, component='ConfirmCancel', modal=true">
                <v-list-item-action>
                  <v-icon>
                    mdi-cancel
                  </v-icon>
                </v-list-item-action>
                  <v-list-item-action>
                    <v-list-item-title>Cancel Batch</v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  :disabled="item.is_processing || item.status == 'POSTED'"
                  @click.stop="selectedBatch = item, component='ConfirmRerun', modal= true">
                <v-list-item-action>
                  <v-icon :disabled="item.is_processing || item.status == 'POSTED'">
                    mdi-run-fast
                  </v-icon>
                </v-list-item-action>
                <v-list-item-action>
                  <v-list-item-title>Re-run Batch</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
              </v-list-item-group>
              <v-list-item-group v-if="postProcesses">
                <v-list-item
                  v-if="['SCAN', 'REBILL'].includes(item.batch_type)"
                  :disabled="(item.status !== 'COMPLETE') && (item.status !== 'POSTED') || (item.is_processing)"
                  @click="sendProcess(item, postProcesses)">
                  <v-list-item-action>
                    <v-icon :disabled="(item.status !== 'COMPLETE') && (item.status !== 'POSTED')">
                      mdi-blender
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-list-item-title>
                      {{ postProcesses.kv_name }}
                    </v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
    <v-divider/>
    <v-toolbar flat color="toolbar">
      <!-- Pagination controls -->
    <v-row align="center" justify="end">
      <v-col cols="2" style="max-width:150px;">
        <v-select class="mt-8" dense :items="pageSizes" label="Items Per Page" select v-model="pageSize"
          :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
        </v-select>
      </v-col>
      <v-btn icon large @click="previous()" :disabled="disablePrevious" class="mx-2">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <small>Page {{page}}</small>
      <v-btn icon large @click="next()" :disabled="disableNext" class="mx-2">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
    </v-toolbar>
    <Component
      v-if="modal"
      :is="component"
      :id='id'
      :value.sync="modal"
      :selectedBatch="selectedBatch"
      :selectedBatches="selectedBatches"
      :selected="selected"
      @close="closeModal"
    />
  </v-container>
</template>
<script>
import BillingBatch from '@/axios/billing-batch'
import { fullWidth } from '@/mixins/fullWidth'
import { displayAlert } from '@/mixins/displayAlert'
import { dateFormat } from '@/mixins/date-format'
import { userAccess } from '@/mixins/user-access'
export default {
  data () {
    return {
      loading: false,
      searching: false,
      batches: [],
      column1: false,
      component: '',
      id:'',
      modal: false,
      search: { value: null },
      searched: false,
      selectedBatches:[],
      selected: [],
      selectedBatch: {},
      filter:'',
      actions: [
        {
          description: 'Batch Details',
          component: 'BatchModal',
          icon: 'mdi-information-variant'},
        {
          description: 'Notes',
          component: 'BatchNotes',
          icon: 'mdi-comment-text-multiple'
        },
        {
          description: 'View Recaps',
          component: 'BatchDetails',
          icon: 'mdi-eye'
        },
        {
          description: 'View Promotions',
          component: 'PromotionTable',
          icon: 'mdi-barcode-scan'
        },
        {
          description: 'Delete Batch',
          component: 'DeleteBatch',
          icon: 'mdi-trash-can'
        }
      ],
      statusList: ['PENDING', 'RUNNING', 'COMPLETE', 'POSTED', 'EXCEPTION', 'CANCELLED', 'POST_PROCESSING', 'NO_ALLOWANCES', 'NO_PROMOS', 'NO_STORES'],
      detailStatus: ['COMPLETE', 'POSTED'],
      states: ['PENDING', 'RUNNING', 'COMPLETE', 'POSTED', 'EXCEPTION', 'CANCELLED', 'POST_PROCESSING', 'NO_ALLOWANCES', 'NO_PROMOS', 'NO_STORES'],
      status:'',
      batchPage: 1,
      page: 1,
      pageSize: 50,
      pageSizes: [50, 100],
      postProcess: {},
      postProcesses: null
    }
  },
  name: 'BatchBilling',
  mixins: [fullWidth, displayAlert, dateFormat, userAccess],
  components: {
    BatchModal: () => import('@/components/BatchModal.vue'),
    BatchDetails: () => import('@/components/BatchDetails.vue'),
    PromotionTable: () => import('@/components/PromotionTable.vue'),
    DeleteBatch: () => import('@/components/DeleteBatch.vue'),
    ConfirmCancel: () => import('@/components/ConfirmCancel.vue'),
    PostBatch: () => import('@/components/PostBatch.vue'),
    BatchNotes: () => import('@/components/BatchNotes.vue'),
    VideoPlayer: () => import('@/components/VideoPlayer.vue'),
    ConfirmRerun: () => import('@/components/ConfirmRerun.vue')
  },
  watch: {
    modal: {
      handler (newValue) {
        if (!newValue) {
          this.selectedBatch = {}
          this.selected = []
          this.selectedBatches = []
          if (['ConfirmRerun', 'ConfirmCancel', 'BatchModal', 'PostBatch', 'DeleteBatch'].includes(this.component)) {
            this.getBatches()
          }
        }
      }
    },
    pageSize: {
      handler() {
          this.getBatches()
      }
    }
  },
  async created () {
    this.nonTableHeight = 228
    if (this.limitAccessByUserParties && this.userRelatedParties.length === 0) {
      await this.$store.dispatch('getUserRelatedParties')
    }
    await this.getBatches()
    this.getPostProcesses()
  },
  computed: {
    buttonDisabled () {
      return (!this.search.key || !this.search.value)
    },
    offset () {
      return (this.page - 1) * this.pageSize
    },
    disablePrevious () {
      return this.offset === 0
    },
    disableNext () {
      return this.batches.length < this.pageSize
    },
    responsibleParties () {
      return this.$store.getters.responsibleParties
    },
    selectedItems () {
      return this.batches.filter(batch => this.states.includes(batch?.status))
    },
    searchParams () {
      return [
        { description: 'Item ID', param: 'item_id' },
        { description: 'UPC', param: 'upc' },
        { description: 'Store Number', param: 'store_number' },
        { description: 'Promo Name', param: 'promo_name' },
        { description: 'Ad Group', param: 'ad_group' },
        { description: 'Wholesaler Store Number', param: 'wholesaler_store_number' },
      ]
    },
    videoLinks () {
      return [
        { label: 'Billing Home Screen', id: '1aac8605-0db3-471c-a935-2d7b63c2f75f'},
        { label: 'Scan Billing Run', id: '8e420410-c762-4107-9c5c-e25fbdbdbb40'}
      ]
    },
    headers () {
      return [
        { sortable: false, class: 'accent', value: 'data-table-select' },
        { text: 'Name', class:'accent white--text', value: 'name', width:'auto' },
        { text: 'Batch Type', class:'accent white--text',  value: 'batch_type', align:'center', width:'auto' },
        { text: 'Status', class:'accent white--text',  value: 'status', align:'center', width:'auto' },
        { text: 'Start Date', class:'accent white--text',  value: 'start_date', align:'center', width:'auto' },
        { text: 'End Date', class:'accent white--text',  value: 'end_date',  align:'center', width:'auto' },
        { text: 'Post Date', class:'accent white--text',  value: 'post_date',  align:'center', width:'auto'},
        { text: 'Batch ID', class:'accent white--text',  value: 'info', sortable: false, align: 'center', width:'auto'},
        { text: '', align:  'start', class:'accent white--text',  sortable: false, value: 'general_col_divider', width: this.column1 ? '1%' : '1%' },
        { text: 'Post Group', class:'accent white--text',  value: 'post_id', sortable: false, align: this.column1 ? 'center' : ' d-none',width:'auto'},
        { text: 'Updated On', class:'accent white--text',  value: 'last_update_date', sortable: false, align: this.column1 ? 'start' : ' d-none', width:'auto' },
        { text: 'User', class:'accent white--text',  value: 'user_id', sortable: false, align: this.column1 ? 'start' : ' d-none', width:'auto' },
        { text: 'Actions', class:'accent white--text',  value: 'actions', sortable: false, align:'center', width:'auto'}
      ]
    }
  },
  methods: {
    onResize () {
      this.tableHeight =  window.innerHeight - 240
    },
    getColor (status) {
      switch (status.toLowerCase()) {
        case 'pending':
          return 'black'
        case 'running':
          return 'orange'
        case 'complete':
          return 'green'
        case 'posted':
          return 'gray'
        case 'exception':
          return 'red'
        case 'cancelled':
          return 'grey lighten-1'
        case 'post_processing':
          return 'blue'
        case 'no_allowances':
          return 'red lighten-2'
        case 'no_promos':
          return 'red lighten-2'
        case 'no_stores':
          return 'red lighten-2'
      }
    },
    closeModal (update = false) {
      this.modal = false
      this.selectedBatches = []
      this.selected = []
      if (update) {
        this.getBatches()
      }
    },
    isDetails (description, status) {
      let detail = ['PENDING', 'RUNNING', 'POST_PROCESSING'].includes(status) 
      return (description === 'View Recaps' && detail)
    },
    isCancellable (item) {
      if (['EXCEPTION', 'POSTED'].includes(item.status)) {
        if (item.status === 'POSTED' && item.statement_generated && item.statement_gen_on !== null) {
          return false
        }
        return true
      }
      return false
    },
    clearSearch() {
      this.page = this.batchPage
      this.search.value = null
      this.searched = false
      this.getBatches()
    },
    async getBatches () {
      this.loading = true
      this.searched = false
      let batches = []
      const params = this.buildBatchListParams()
      try {
        const res = await BillingBatch.getList(params)
        if (res?.data?.length > 0) {
          batches = res.data.map(batch => {
            if (batch.batch_type === 'STANDARD') {
              batch.batch_type = 'SCAN'
            }
            return batch
          })
        }
        this.batches = batches
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    async searchBatches () {
      this.loading = true
      this.searching = true
      if (!this.searched) {
        this.batchPage = this.page
        this.page = 1
      }
      const key = this.search.key?.param
      const value = this.search.value
      const params = this.buildBatchListParams()
      if (key && value) {
        params[`${key}`] = value
      }
      try {
        const res = await BillingBatch.search(params)
        this.batches = res?.data || []
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
        this.searched = true
        this.searching = false
      }
    },
    buildBatchListParams () {
      const params = {
        limit: this.pageSize,
        offset: this.offset
      }
      if (this.limitAccessByUserParties) {
        params.party_ids = [...this.userBatchPartyIds]
      }
      return params
    },
    async getPostProcesses(){
      const kv_constant = 'BILLING.POST_PROCESS'
      const key_id ='DAG_NAME'
      const params ={key_id:key_id}
      await this.$KeyValues.getKeyValueByKeyId(kv_constant, params).then(res=>{
        if(res.data){
        this.postProcesses = res.data
        }
      }).catch(err=>{
        this.handleError(err)
      })
    },
    async sendProcess(batch, process){
      const params={responsible_party:batch.responsible_party_id}
      await this.$BillingBatch.triggerStep(batch.id, process, params).then(() =>{
        this.$store.dispatch('setSnackbar', { status: 'success', text: `Post Process Triggered!` })
      }).catch(err=>{
        this.handleError(err)
      }).finally(() => {
        this.getBatches()
      })
    },
    getResponsiblePartyName (id) {
      if (this.responsibleParties.length > 0) {
        const party = this.responsibleParties.find(p => p.id === id)
        if (party) {
          return party.name
        }
      }
      return ''
    },
    copyValue (value) {
      navigator.clipboard.writeText(value)
      this.$store.dispatch('setSnackbar', { status: 'success', text: `Copied!` })
    },
    previous () {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
      if (this.searched) {
        this.searchBatches()
      } else {
        this.getBatches()
      }
    },
    next () {
      this.page = this.page + 1
      if (this.searched) {
        this.searchBatches()
      } else {
        this.getBatches()
      }
    }
  }
}
</script>
<style scoped>
  .noWrap {
    white-space: nowrap;
  }
</style>